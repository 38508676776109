<!-- This components is a middleware for recognizing editing course type -->
<template>
  <div v-if="isDataFetched">
    <EditSwap
      v-if="orderType === 'Wymiana'"
      :editing-order="order"
    />
    <EditPickup
      v-else-if="orderType === 'Zabranie'"
      :editing-course="dialog.item"
    />
    <EditOrder
      v-else
      :editing-order="order"
    />
  </div>
</template>

<script>
import EditOrder from './EditOrder.vue'
import EditSwap from './EditSwap.vue'
import EditPickup from './EditPickup.vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    EditOrder,
    EditSwap,
    EditPickup,
  },
  data: () => ({
    isDataFetched: false
  }),
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog,
      order: state => state.order.entity,
    }),
    orderType () {
      if (this.dialog.item.swapCourse) return 'Wymiana'
      return this.dialog.item.courseType
    },
  },
  created () {
    if (this.dialog.item.courseType === 'Zabranie') this.setDialogSize('small')
    const { id, client } = this.dialog.item.order || this.dialog.item
    if (id !== -1) { // -1 stands for anyContainer flag
      const getOrder = this.getOrder(id)
      const getClient = this.getClient(client.id)
      // single client is being fetched for ChooseClient and ChooseClientAddress components
      return Promise.all([getOrder, getClient])
        .then(() => { this.isDataFetched = true })
    } else {
      this.isDataFetched = true
    }
  },
  beforeDestroy () {
    this.clearSingleOrder()
  },
  methods: {
    ...mapActions({
      getOrder: 'order/getSingleOrder',
      clearSingleOrder: 'order/clearSingleOrder',
      getClient: 'client/getSingleClient',
      setDialogSize: 'layout/setDialogSize',
    })
  }
}
</script>
